<script>
import Layout from "../../layouts/main";
import Category from "@/components/category/category";
import PrimaryCategory from "@/components/category/primary-category";
import SecondaryCategory from "@/components/category/secondary-category";
import CategoriesServiceApi from "@/service/api/categories";

export default {
  components: { Layout, Category, PrimaryCategory, SecondaryCategory },
  data() {
    return {
      categoryData: null,
      primaryCategoryData: null,
      primaryCategorySelect: null,
      primaryLoad: false,
    };
  },
  mounted() {
    this.primaryLoad = true;
    CategoriesServiceApi.getAllMainCategories()
      .then((response) => {
        this.categoryData = response.data;
      })
      .finally(() => {
        this.primaryLoad = false;
      });
  },
  methods: {
    primaryCategoryDataChanged(value) {
      this.primaryCategoryData = value;
    },
    categorySelected(value) {
      this.primaryCategorySelect = value.id;
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <Category :categoryData="categoryData" :primaryLoad="primaryLoad" />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <PrimaryCategory
          :mainCategoryData="categoryData"
          v-on:categorySelect="categorySelected"
          v-on:primaryCategoryData="primaryCategoryDataChanged"
        />
      </div>
      <div class="col-lg-6">
        <SecondaryCategory
          :primaryCategory="primaryCategorySelect"
          :primaryCategoryData="primaryCategoryData"
        />
      </div>
    </div>
  </Layout>
</template>
