<script>
import CategoriesServiceApi from "@/service/api/categories";
import { required } from "vuelidate/lib/validators";
import Spinner from "@/components/spinner";
export default {
  props: ["mainCategoryData"],
  components: { Spinner },
  data() {
    return {
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 15, 25, 50, 100],
      categoryData: [],
      filter: null,
      filterOn: [],
      categoryDelete: null,
      loadingPrimary: false,
      fields: [
        { key: "id", sortable: true, label: "ID" },
        { key: "name", sortable: true, label: "Name" },
        { key: "category", label: "Category" },
      ],
      categoryForm: {
        id: null,
        name: "",
        name_ar: "",
        category_id: null,
      },
      categoryFormsubmitted: false,
    };
  },
  validations: {
    categoryForm: {
      name: { required },
      category_id: { required },
    },
  },
  computed: {
    categorySelect: function() {
      if (this.mainCategoryData) {
        return this.mainCategoryData.map((val) => {
          const options = {
            label: `${val.name_en} - ${val.name_ar}`,
            value: val.id,
          };
          return options;
        });
      }
      return [];
    },
    rows() {
      if (this.totalRows < this.categoryData.length) return this.totalRows;
      else return this.totalRows;
    },
  },
  mounted() {
    this.loadCategory();
  },
  methods: {
    formReset() {
      this.categoryForm.id = null;
      this.categoryForm.name = "";
      this.categoryForm.name_ar = "";
      this.categoryForm.category_id = null;
      this.categoryFormsubmitted = false;
    },
    loadCategory() {
      this.loadingPrimary = true;
      CategoriesServiceApi.getPrimaryCategories()
        .then((response) => {
          this.categoryData = response.data.map((item) => {
            return {
              category: item.category,
              category_id: item.category_id,
              id: item.id,
              name: item.name,
              name_ar: item.name_ar,
              status: item.status === "active" ? true : false,
            };
          });
          this.$emit("primaryCategoryData", this.categoryData);
          this.totalRows = this.categoryData.length;
          this.categoryDelete = null;
        })
        .finally(() => {
          this.loadingPrimary = false;
        });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    addCategory() {
      this.categoryFormsubmitted = true;
      this.$v.$touch();
      if (this.$v.categoryForm.$invalid) {
        return;
      } else {
        const data = {
          action: "add",
          name: this.categoryForm.name,
          name_ar: this.categoryForm.name_ar,
          category_id: this.categoryForm.category_id,
        };
        CategoriesServiceApi.primaryCategoryAction(data).then((response) => {
          if (response.data.success) {
            this.$refs["categoryAdd"].hide();
            this.formReset();
            this.loadCategory();
            this.categoryAddedMessage(response.data.primary_category);
          }
        });
      }
    },
    updateCategorySelect(item) {
      this.categoryForm = { ...item };
    },
    categoryAddedMessage(data, variant = "success") {
      this.$bvToast.toast(`${data[0].name} is added! `, {
        title: `Category added ${variant || "successfully"}`,
        variant: variant,
        solid: true,
      });
    },
    categoryToggleMessage(data, variant = "success") {
      this.$bvToast.toast(`${data.name} is ${data.status}! `, {
        title: `Primary category status`,
        variant: variant,
        solid: true,
      });
    },
    categoryUpdateMessage(data, variant = "success") {
      this.$bvToast.toast(`${data.name} is updated! `, {
        title: `Primary category updated!`,
        variant: variant,
        solid: true,
      });
    },
    primaryInactiveToggler(item_id = null) {
      const item = this.categoryData.find(({ id }) => id === item_id);
      if (item) {
        const data = {
          action: "delete",
          primary_id: item.id,
        };
        CategoriesServiceApi.primaryCategoryAction(data).then((response) => {
          if (response.data.success) {
            this.loadCategory();
            this.categoryToggleMessage(response.data.primary_category);
          }
        });
      }
    },
    updateCategory(id) {
      this.categoryFormsubmitted = true;
      this.$v.$touch();
      if (this.$v.categoryForm.$invalid) {
        return;
      } else {
        if (id) {
          const data = {
            action: "edit",
            name: this.categoryForm.name,
            name_ar: this.categoryForm.name_ar,
            primary_id: id,
            category_id: this.categoryForm.category_id,
          };
          CategoriesServiceApi.primaryCategoryAction(data).then((response) => {
            if (response.data.success) {
              this.$refs["categoryAdd"].hide();
              this.formReset();
              this.loadCategory();
              this.categoryUpdateMessage(response.data.primary_category);
            }
          });
        }
      }
    },

    reloadAllData() {
      this.loadCategory();
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title" style="text-align:center">Primary Category</h4>
          <button @click="reloadAllData" class="btn btn-sm btn-secondary">
            <i class="ri-refresh-line"></i>
          </button>
          <button
            v-b-modal.categoryAdd
            @click="formReset()"
            class="btn btn-sm btn-primary float-right"
          >
            <i class="ri-add-box-fill align-middle"></i> Add
          </button>
          <div class="clearfix"></div>
          <div class="row" style="padding-top:10px;">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select>
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <Spinner :show="loadingPrimary" />
          <div
            class="table-responsive mb-0"
            v-if="categoryData && categoryData.length"
          >
            <b-table
              :items="categoryData"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(name)="row">
                <div>
                  <a
                    class="hover-cursor"
                    @click="
                      () =>
                        $emit('categorySelect', {
                          id: row.item.id,
                        })
                    "
                  >
                    {{ row.item.name }}
                  </a>
                </div>

                <div>
                  <b-badge
                    :variant="
                      row.item.status === 'active' || row.item.status === true
                        ? 'success'
                        : 'danger'
                    "
                    >{{ row.item.status ? "Active" : "Inactive" }}</b-badge
                  >
                </div>
              </template>

              <template v-slot:cell(category)="row">
                <div style="display: flex; flex-wrap: wrap;">
                  <div style="flex: 30%;">
                    <img :src="row.item.category.image" width="32" alt="" />
                  </div>
                  <div style="flex: 70%;text-align:left">
                    <div class="row">
                      <div class="col-8">
                        <div>{{ row.item.category.name_en }}</div>
                        <div>{{ row.item.category.name_ar }}</div>
                      </div>
                      <div class="col-4 action-btn">
                        <div class="row">
                          <div class="col-4">
                            <a
                              v-b-modal.categoryAdd
                              v-on:click="updateCategorySelect(row.item)"
                              class="mr-3 text-primary"
                              v-b-tooltip.hover
                              data-toggle="tooltip"
                              title="Edit"
                            >
                              <i class="mdi mdi-pencil font-size-18"></i>
                            </a>
                          </div>
                          <div class="col-8" style="padding-top:5px;">
                            <b-form-checkbox
                              v-model="row.item.status"
                              @change="primaryInactiveToggler(row.item.id)"
                              switch
                            >
                            </b-form-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </b-table>

            <div class="dataTables_paginate paging_simple_numbers">
              <ul class="pagination pagination-rounded mb-0 float-right">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
          <div v-else>
            <div v-if="!loadingPrimary" style="text-align:center">
              No Data Availble
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      ref="categoryAdd"
      id="categoryAdd"
      centered
      :title="
        categoryForm.id ? 'Update primary category' : 'Add primary category'
      "
      hide-footer
    >
      <form class="needs-validation ml-4 mr-4" @submit.prevent>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label>Category Name</label>
              <input
                type="text"
                v-model="categoryForm.name"
                class="form-control"
                placeholder="Name English"
                value=""
                :class="{
                  'is-invalid':
                    categoryFormsubmitted && $v.categoryForm.name.$error,
                }"
              />
              <div
                v-if="categoryFormsubmitted && $v.categoryForm.name.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.categoryForm.name.required"
                  >Name is required!</span
                >
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label>Category Name (In Arabic )</label>
              <input
                type="text"
                v-model="categoryForm.name_ar"
                class="form-control"
                placeholder="Name Arabic"
                value=""
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label>Main category</label>
              <FormulateInput
                v-model="categoryForm.category_id"
                type="select"
                :options="categorySelect"
                placeholder="Choose a main
            category"
                :error="
                  categoryFormsubmitted && $v.categoryForm.category_id.$error
                    ? 'Please select a category.'
                    : null
                "
              />
            </div>
          </div>
        </div>
        <div class="mt-3 mb-4">
          <button
            v-if="!categoryForm.id"
            type="button"
            v-on:click="addCategory()"
            class="btn btn-primary float-right"
            style="width:100px"
          >
            Add
          </button>
          <button
            v-else
            type="button"
            v-on:click="updateCategory(categoryForm.id)"
            class="btn btn-primary float-right"
            style="width:100px"
          >
            Update
          </button>
          <button
            type="button"
            @click="
              $bvModal.hide('categoryAdd');
              formReset();
            "
            class="btn btn-secondary"
            style="width:100px"
          >
            Cancel
          </button>
        </div>
      </form>
    </b-modal>

    <b-modal
      ref="categoryDelete"
      id="category-delete"
      centered
      title="Delete primary category"
      hide-footer
    >
      <p>
        Delete
      </p>
      <div class="modal-footer">
        <button
          type="button"
          v-on:click="deleteCategory()"
          class="btn btn-primary"
        >
          Please confirm before delete
        </button>
        <button
          type="button"
          @click="
            $bvModal.hide('category-delete');
            () => (brandDelete = null);
          "
          class="btn btn-secondary"
        >
          Close
        </button>
      </div>
    </b-modal>
  </div>
</template>

<style>
.action-btn a {
  background-color: antiquewhite;
  padding: 3px;
  border-radius: 50%;
}
.action-btn a:hover {
  background-color: #f1f1f1;
}
.hover-cursor:hover {
  cursor: pointer;
}
</style>
