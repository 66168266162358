<script>
import Spinner from "@/components/spinner";
export default {
  props: ["categoryData", "primaryLoad"],
  components: { Spinner },
  data() {
    return {
      categoryDelete: null,
      fields: [
        { key: "id", sortable: true, label: "ID" },
        { key: "name_en", sortable: true, label: this.$t("brands.table.name") },
        {
          key: "desc_en",
          sortable: true,
          label: this.$t("brands.table.description"),
        },
        {
          key: "status",
          sortable: true,
          label: this.$t("brands.table.status"),
        },
      ],
    };
  },
  computed: {},
  mounted() {},
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <!-- Table -->

          <div
            class="table-responsive mb-0"
            v-if="categoryData && categoryData.length"
          >
            <b-table :items="categoryData" :fields="fields" responsive="sm">
              <template v-slot:cell(name_en)="row">
                <div style="display: flex; flex-wrap: wrap;">
                  <div style="flex: 30%;">
                    <img :src="row.item.image" width="32" alt="" />
                  </div>
                  <div style="flex: 70%;text-align:left">
                    <div>{{ row.value }}</div>
                    <div>{{ row.item.name_ar }}</div>
                  </div>
                </div>
              </template>
              <template v-slot:cell(desc_en)="row">
                <div>{{ row.value }}</div>
                <div>{{ row.item.desc_ar }}</div>
              </template>
            </b-table>
          </div>
          <div v-else>
            <div v-if="!primaryLoad">
              No Data Availabe
            </div>
          </div>
          <Spinner :show="primaryLoad" />
        </div>
      </div>
    </div>
  </div>
</template>
